/**
 * Footer Manipulation
 *
 * @since 1.0.0
 * @package iastage
 */
(function($) {
	$('footer .footer-content .col-2 .socials').wrapAll('<li id="footer-social"></li>');
	$('#footer-social').appendTo('#footer-menu');

	// Run code only on Product Category page 
	if ( $('.featured-product').length > 0 ) {
		var url_array = window.location.href.split('/');
		// Assumes URLs end with '/' causing last item to be ''
		var current_category = url_array[url_array.length-2];
		// Return Nodelist object
		var featured_products = $('.featured'); //document.getElementsByClassName('featured');
		var fp_count = featured_products.length;

		// Traverse Featured Products
		for (var i=0; i<fp_count; i++) {
			// No category chosen: break
			if ( current_category.includes('products') ) {
				break;
			}
			else {
				var isMatch = featured_products[i].getAttribute('data-category').includes(current_category);

				// Non-IE9 approach
				if ( featured_products[i].classList ) {
					if ( isMatch ) {
						featured_products[i].classList.remove('hide');
						continue;
					}
					else {
						featured_products[i].classList.add('hide');
					}
					// No matches? : unhide default Featured Product
					if ( i == (fp_count-1) && $('.featured:not(.hide)').length <= 0 ) {
						if ( $('.default').length > 0 )
							 $('.default')[0].classList.remove('hide');
					}
				}
				// For IE9
				else {
					var classes = featured_products[i].className.split(' ');
					var index = classes.indexOf('hide');

					// If match and hidden -> show
					if (isMatch && index >= 0) {
						classes.splice(index, 1);
					}
					// If no match and not hidden -> hide
					else if (!isMatch && index < 0) {
						classes.push('hide');
					}
					featured_products[i].className = classes.join(' ');

					// No matches? : unhide default Featured Product
					if ( i == (fp_count-1) && $('.featured-product').not('.hide').length == 0 ) {
						var defaultElement =  $('.default')[0];
						classes = defaultElement.className.split(' ');
						index = classes.indexOf('hide');
						if ( index >= 0 )
							 classes.splice(index, 1);
						defaultElement.className = classes.join(' ');
					}
				}
			}
		}
	}

	// Run code only on Product Category page
	if ( $('.product-grid').length > 0 ) {
		var featured_product = $('.featured').not('.hide').find('h2');

		var other_products = $('.product-grid .card-wrapper');
		var other_products_h2 = $('.product-grid .card-wrapper').find('h2');

		// Check featured product for this category exists
		if ( featured_product.length > 0 && featured_product[0].textContent !== '' ) {
			for (var i=0; i<other_products.length; i++) {

				// Hide if duplicate product
				if ( featured_product[0].textContent.toLowerCase() == other_products_h2[i].textContent.toLowerCase() ) {
					
					if ( other_products[i].classList ) {
						other_products[i].classList.add('hide');
					}
					// For IE9
					else {
						var classes = other_products[i].classname.split(' ');
						var index = classes.indexOf('hide');

						if ( index >= 0 ) { classes.splice(index, 1); }
						else { classes.push('hide'); }

						other_products[i].className = classes.join(' ');
					}
				}
			} // for
		}
	}

	// Run code only on Services page
	// 
	if ( $('body.post-type-archive-ia_service').length > 0 ) {
		var featuredService = $('.featured-service');
		var services = $('.service-grid a').not( featuredService );

		for(var i=0; i<services.length; i++) {

			if ( services[i].href.toLowerCase() === featuredService[0].href.toLowerCase() ) {
				services[i].style.display = 'none'
			}
		}

	}

	// Run code only on Portfolio page :
	// Links from Home page sliders cause corresponding Portfolio item modal to auto-display
	if ( $('[data-tag]').length > 0 ) {

		// Get query arg from URL, match with data-tag, open featherlight
		var urlParams = new URLSearchParams( window.location.search );

		if ( urlParams.has('item') ) {
			var selector = '[data-tag=' + urlParams.get('item') + ']';
			var triggerElement = $( selector )
			var id = $( selector ).attr( 'href' );
			var lightboxElement = $( id );

			// Click required to trigger featherlight AND flickity carousel
			//$.featherlight( lightboxElement );
			triggerElement.click();
		}
	}





})(jQuery);