/**
 * Tab switching script
 *
 * @since 1.0.0
 * @package iastage
 */

(function($) {
	$('.tabs .tab-links a').on('click', function(e)  {
		var currentAttrValue = $(this).attr('href');

		// Show/Hide Tabs
		$('.tabs ' + currentAttrValue).show().siblings().hide();

		// Change/remove current tab to active
		$(this).parent('li').addClass('active').siblings().removeClass('active');

		e.preventDefault();
	});

	$('.tab-content .tab .mobile-dropdown a').on('click', function(e)  {
		var currentAttrValue = $(this).attr('href');

		// Change/remove current tab to active
		$(this).parents('.tab').toggleClass('active');

		if( $(this).parents('.tab').hasClass('active') ) {
			// Show/Hide Tabs
			$('.tabs ' + currentAttrValue + ' .tab-content-inner').show();
		} else {
			$('.tabs ' + currentAttrValue + ' .tab-content-inner').hide();
		}

		e.preventDefault();
	});

	$(window).bind('load resize', function() {
		var sectionwidth = $('section.services, section.tab-content').width();
		if (sectionwidth > 1199) {
			$('section.services #tab1, section.tab-content #tab1').addClass('active');
		}
	});
})(jQuery);